<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1>
            <b-icon icon="file-earmark-person" />
            {{ "Gestión de estudiantes (" + elements + ")" }}
          </h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row>
                <!--<b-col lg="6" sm="12" class="mb-3"-->
                <b-col lg="12" sm="12" class="mb-3"
                  ><b-form-select
                    v-model="selectPeriod"
                    @change="changePeriod()"
                    :options="periods"
                  />
                  <b-form-select
                    v-show="selectPeriod != ''"
                    @change="changeProgram()"
                    v-model="selectProgram"
                    :options="programs"
                  />
                  <b-form-select
                    v-show="selectProgram != ''"
                    @change="changeTurn()"
                    v-model="selectTurn"
                    :options="turns"
                  />
                </b-col>
                <!--<b-col lg="6" sm="12" class="mb-3">
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="keyword"
                      placeholder="Buscar"
                      type="text"
                    />
                  </b-input-group>
                  <p>
                    {{
                      this.selectPeriod +
                      " / " +
                      this.selectProgram +
                      " / " +
                      this.selectTurn
                    }}
                  </p>
                </b-col>-->
              </b-row>
              <div v-if="desserts.length != 0">
                <b-table
                  striped
                  responsive
                  stacked="lg"
                  :items="desserts"
                  :fields="fields"
                >
                  <template #cell(name)="row">
                    {{ row.item.name }}
                  </template>
                  <template #cell(lastName)="row">
                    {{
                      row.item.lastnameFather + " " + row.item.lastnameMother
                    }}
                  </template>
                  <template #cell(paymentPercentage)="row">
                    {{ row.item.paymentPercentage + " %" }}
                  </template>
                </b-table>
              </div>
              <div class="text-center" v-else>
                <em>No hay datos en usando estos filtros</em>
              </div>
              <b-pagination
                v-if="desserts.length != 0"
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="elements"
                per-page="10"
                @input="changeQueryPagination()"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    elements: 0,
    currentPage: 1,
    periods: [],
    programs: [],
    turns: [],
    selectPeriod: "",
    selectProgram: "",
    selectTurn: "",
    keyword: "",
    selected: "",
    desserts: [],
    fields: [
      { key: "index", label: "#", isRowHeader: true },
      { key: "identificationDocument", label: "DNI" },
      { key: "email", label: "Correo" },
      { key: "lastName", label: "Apellidos" },
      { key: "name", label: "Nombres" },
      { key: "phoneNumber", label: "Celular" },
      { key: "periodName", label: "Ciclo" },
      { key: "programName", label: "Programa" },
      { key: "turnName", label: "Turno" },
      { key: "paymentPercentage", label: "pago(%)" },
    ],
  }),

  created() {
    this.initialize();
    this.getPeriod();
  },

  methods: {
    async changeQueryPagination() {
      this.selected = "circular";
      await this.$axios
        .get(
          "/person/student?period=" +
            this.selectPeriod +
            "&program=" +
            this.selectProgram +
            "&turn=" +
            this.selectTurn +
            "&page=" +
            this.currentPage,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.elements = res.data.elements;
          this.desserts = res.data.allStudent;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async changeQuery() {
      this.currentPage = 1;
      this.selected = "circular";
      await this.$axios
        .get(
          "/person/student?period=" +
            this.selectPeriod +
            "&program=" +
            this.selectProgram +
            "&turn=" +
            this.selectTurn +
            "&page=" +
            this.currentPage,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.elements = res.data.elements;
          this.desserts = res.data.allStudent;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    //Obtención de desplegables
    async getPeriod() {
      this.selected = "circular";
      await this.$axios
        .get("/academic", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var temp = [{ value: "", text: "-- Seleccione Periodo --" }];
          for (const key in res.data) {
            var data = {
              value: res.data[key].id,
              text: res.data[key].name,
            };
            temp.push(data);
          }
          this.periods = temp;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async getProgram(idPeriod) {
      this.selected = "circular";
      await this.$axios
        .get("/person/program?period=" + idPeriod, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var temp = [{ value: "", text: "-- Seleccione Programa --" }];
          this.programs = temp.concat(res.data);
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async getTurn() {
      this.selected = "circular";
      await this.$axios
        .get("/person/group", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var temp = [{ value: "", text: "-- Seleccione Turno --" }];
          this.turns = temp.concat(res.data);
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    //Cambios al seleccionar desplegables
    async changePeriod() {
      this.selectProgram = "";
      this.selectTurn = "";
      if (this.selectPeriod != "") this.getProgram(this.selectPeriod);
      this.changeQuery();
    },
    async changeProgram() {
      this.selectTurn = "";
      if (this.selectProgram != "") this.getTurn();
      this.changeQuery();
    },
    async changeTurn() {
      this.changeQuery();
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/person/student?period=&program=&turn=&page=", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.elements = res.data.elements;
          this.desserts = res.data.allStudent;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>
